import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import {
  chain,
  Dictionary,
  filter as lodashFilter,
  isEmpty,
  join,
  filter,
  find,
  groupBy,
  pickBy,
  omitBy,
} from 'lodash';
import { createExtendedSummaryRow, createSummaryRow } from '@/helpers/leistungen-summary';
import { setStoreFromParams } from '@/helpers/leistungen';
import { metricsApi } from '@/services/data';
import { authStore } from '@/state/authStore';
import { clientStateStore } from '@/state/clientStateStore';
import { usersStore } from '@/state/usersStore';
import { createDirectStore } from 'direct-vuex';
import { last30Days } from '@rose/common-ui';
import { roseDayjs } from '../../../base';

import {
  ILeistungTerminKommentarBase,
  ILeistungenBase,
  ILeistungenSummaryRow,
  ILeistungenTermineKommentarParams,
  IOffeneGOZLeistungenExtended,
  IOffeneLeistungenTerminInfosTagLeistungen,
  IOffeneLeistungenVerlaufResult,
  IOtherLeistungenBase,
  ITeam,
  UIKassePrivatType,
} from '../../../types';
import { viewStateStore } from '@/state/viewStateStore';
import { folgeTermineFilter, SpezialFilterHandler, wvlFilter } from '@/helpers/spezialFilterHandler';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    leistungen: [] as IOffeneGOZLeistungenExtended[],
    verlauf: [] as IOffeneLeistungenVerlaufResult['verlauf'],
    leistungenLoading: false,
    leistungenVerlaufLoading: false,
    leistungenLoadingError: null as null | string,
    leistungenVerlaufLoadingError: null as null | string,
    leistungSelected: null as null | IOffeneGOZLeistungenExtended,
    selectedPatientKommentar: null as any,
    selectedTagInfos: [] as IOffeneLeistungenTerminInfosTagLeistungen[],
    termineSelectedLoading: null as null | boolean,
    spezialFilterHandler: new SpezialFilterHandler<IOffeneGOZLeistungenExtended>({
      ...folgeTermineFilter,
      ...wvlFilter,
    }),
    patientSearchInput: '',
    leistungSearchInput: '',
    kassePrivat: UIKassePrivatType.ALLE,
    dateEnabled: false,
    date: {
      startDate: roseDayjs(last30Days[0]).format('YYYY-MM-DD'),
      endDate: roseDayjs(last30Days[1]).format('YYYY-MM-DD'),
    },
    refDate: roseDayjs().format('YYYY-MM-DD'),
    subtractHkp: false,
    teamSelected: null as null | ITeam,
    leistungserbringerSelected: null as null | ITeam,
    showExtendedSummaryRow: false,
    summaryRow: [] as ILeistungenSummaryRow[],
    extendedSummaryRow: [] as ILeistungenSummaryRow[],
  }),
  getters: {
    filterGroups(state) {
      return state.spezialFilterHandler.filterGroupsWithCounts(state.leistungen);
    },
    activeFilterKeys(state) {
      return state.spezialFilterHandler.activeFilterKeys();
    },
    leistungen(state) {
      return state.leistungen;
    },
    verlauf(state) {
      return state.verlauf;
    },
    leistungenLoading(state) {
      return state.leistungenLoading;
    },
    leistungenVerlaufLoading(state) {
      return state.leistungenVerlaufLoading;
    },
    leistungenLoadingError(state) {
      return state.leistungenLoadingError;
    },
    leistungenVerlaufLoadingError(state) {
      return state.leistungenVerlaufLoadingError;
    },
    leistungSelected(state) {
      return state.leistungSelected;
    },
    selectedTagInfos(state) {
      return state.selectedTagInfos;
    },
    termineSelectedLoading(state) {
      return state.termineSelectedLoading;
    },
    leistungenFiltered(state) {
      let filtered = chain(state.leistungen);
      if (state.patientSearchInput) {
        filtered = filtered.filter(l =>
          `${l.name} ${l.vorname} ${l.patid}`.toLowerCase().includes(state.patientSearchInput),
        );
      }
      if (state.leistungSearchInput) {
        filtered = filtered.filter(l => l.leistungsids?.includes(state.leistungSearchInput));
      }

      if (state.kassePrivat === UIKassePrivatType.KASSE) {
        filtered = filtered.filter(l => l.privat === false);
      }

      if (state.kassePrivat === UIKassePrivatType.PRIVAT) {
        filtered = filtered.filter(l => l.privat === true);
      }

      return state.spezialFilterHandler.filterEntries(filtered.value());
    },
    patientSearchInput(state) {
      return state.patientSearchInput;
    },
    leistungSearchInput(state) {
      return state.leistungSearchInput;
    },
    dateEnabled(state) {
      return state.dateEnabled;
    },
    date(state) {
      return state.date;
    },
    refDate(state) {
      return state.refDate;
    },
    refDateOverriden(state) {
      return !roseDayjs().isSame(state.refDate, 'day');
    },
    subtractHkp(state) {
      return state.subtractHkp;
    },
    teamSelected(state) {
      return state.teamSelected;
    },
    leistungserbringerSelected(state) {
      return state.leistungserbringerSelected;
    },
    showExtendedSummaryRow(state) {
      return state.showExtendedSummaryRow;
    },
    summaryRow(state) {
      return state.summaryRow;
    },
    extendedSummaryRow(state) {
      return state.extendedSummaryRow;
    },
    selectedPatientKommentar(state) {
      return state.selectedPatientKommentar;
    },
    kassePrivat(state) {
      return state.kassePrivat;
    },
  },
  mutations: {
    setLeistungen(state, items: IOffeneGOZLeistungenExtended[]) {
      // make wiedervorlage field reactive
      state.leistungen = items.map(l => ({ ...{ wiedervorlage: null }, ...l }));
    },
    setVerlauf(state, verlauf: IOffeneLeistungenVerlaufResult['verlauf']) {
      state.verlauf = verlauf;
    },
    setLeistungenLoading(state, bool: boolean) {
      state.leistungenLoading = bool;
    },
    setLeistungenVerlaufLoading(state, bool: boolean) {
      state.leistungenVerlaufLoading = bool;
    },
    setLeistungenLoadingError(state, error: string | null) {
      state.leistungenLoadingError = error;
    },
    setLeistungenVerlaufLoadingError(state, error: string | null) {
      state.leistungenVerlaufLoadingError = error;
    },
    setLeistungSelected(state, item: IOffeneGOZLeistungenExtended | null) {
      state.leistungSelected = item;
    },
    setSelectedPatientKommentar(state, kommentar: any) {
      state.selectedPatientKommentar = kommentar;
    },
    setSelectedTagInfos(state, items: IOffeneLeistungenTerminInfosTagLeistungen[]) {
      state.selectedTagInfos = items;
    },
    setTermineSelectedLoading(state, isLoading: boolean) {
      state.termineSelectedLoading = isLoading;
    },
    setPatientSearchInput(state, searchInput) {
      state.patientSearchInput = searchInput;
    },
    setLeistungSearchInput(state, searchInput) {
      state.leistungSearchInput = searchInput;
    },
    setDate(state, date) {
      state.date = date;
    },
    setRefDate(state, date) {
      state.refDate = date;
    },
    setDateEnabled(state, dateEnabled) {
      state.dateEnabled = dateEnabled;
    },
    setSubtractHkp(state, substract) {
      state.subtractHkp = substract;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
    setLeistungserbringerSelected(state, team) {
      state.leistungserbringerSelected = team;
    },
    switchExtendedSummaryRow(state) {
      state.showExtendedSummaryRow = !state.showExtendedSummaryRow;
    },
    setSummaryRow(state, summary) {
      state.summaryRow = summary;
    },
    setExtendedSummaryRow(state, summary) {
      state.extendedSummaryRow = summary;
    },
    setKassePrivat(state, kassePrivat: UIKassePrivatType) {
      state.kassePrivat = kassePrivat;
    },
    updateSelectedTagLeistungKommentar(state, kommentar: ILeistungenTermineKommentarParams) {
      const entryKommentar: ILeistungTerminKommentarBase = {
        kommentar: kommentar.kommentar,
        authorName: usersStore.getters.userNameByEmail,
        updatedAt: new Date().toISOString(),
        ersteller: authStore.getters.userEmail,
      };

      const entry = state.selectedTagInfos.find(etry => etry.tag === kommentar.tag);
      if (entry) {
        entry.kommentar = entryKommentar;
      }

      if (!kommentar.tag) {
        state.selectedPatientKommentar = entryKommentar;
      }

      if (state.leistungSelected) {
        state.leistungSelected.hasComments =
          state.selectedTagInfos.some(e => e.kommentar?.kommentar) || state.selectedPatientKommentar?.kommentar;
      }
    },
  },
  actions: {
    async getData(context, routeParams?: Dictionary<any>) {
      const { state, dispatch, commit } = rootActionContext(context);

      commit.setLeistungenLoading(true);
      if (isEmpty(state.verlauf)) {
        commit.setLeistungenVerlaufLoading(true);
        commit.setLeistungenVerlaufLoadingError(null);
      }
      setStoreFromParams(routeParams);

      const queryParams = !isEmpty(routeParams)
        ? routeParams
        : {
            leistungsbehandler: join(state.leistungserbringerSelected?.mitglieder),
            stammbehandler: state.teamSelected?.id,
            subtracthkp: state.subtractHkp,
            from: state.dateEnabled ? state.date?.startDate : undefined,
            to: state.dateEnabled ? state.date?.endDate : undefined,
            refdate: state.refDate,
          };
      router.push({ name: 'LeistungenUebersicht', query: queryParams }).catch(() => {});

      if (isEmpty(state.verlauf)) {
        metricsApi.leistungen
          .getOffeneLeistungenVerlauf(queryParams)
          .then(verlaufResult => {
            commit.setVerlauf(verlaufResult.verlauf);
            console.log('leistungenVerlauf', verlaufResult.verlauf);
          })
          .catch(e => {
            commit.setLeistungenVerlaufLoadingError('Fehler beim Laden: ' + e.message);
          })
          .finally(() => {
            commit.setLeistungenVerlaufLoading(false);
          });
      }

      const leistungenResult = await metricsApi.leistungen.getOffeneLeistungen(queryParams);
      const offeneLeistungen = leistungenResult.offeneLeistungen;
      await clientStateStore.getters.names.augment(offeneLeistungen, undefined, { withPAR: true });
      await clientStateStore.getters.folgeterminInfo.augment(offeneLeistungen);
      commit.setLeistungen(lodashFilter(offeneLeistungen, l => l.gesamt > 0));
      await dispatch.updateSummaryRows('loading');
      commit.setLeistungenLoading(false);

      console.log('leistungen', offeneLeistungen);
    },
    async setLeistungSelected(context, item: IOffeneGOZLeistungenExtended | null) {
      const { commit, state, getters } = rootActionContext(context);

      if (!item) {
        commit.setLeistungSelected(item);
        commit.setTermineSelectedLoading(false);
        commit.setSelectedTagInfos([]);
        return;
      }

      console.log('leistungSelected', item);
      commit.setLeistungSelected(item);
      commit.setTermineSelectedLoading(true);

      const { tagLeistungen, patientKommentar } = await metricsApi.leistungen.getLeistungenTermine({
        from: item.tagMin.toString(),
        to: item.tagMax.toString(),
        patid: item.patid,
      });
      const details = await metricsApi.leistungen.getOffeneLeistungenDetails(
        item.patid,
        getters.refDateOverriden ? state.refDate : undefined,
      );
      console.log('leistungen termine/details', tagLeistungen, details);

      const termine = tagLeistungen.map((tagLeistung: any) => tagLeistung.termine?.tagtermine || []).flat();
      const resolveContainer = [
        {
          folgetermine: termine,
        },
      ];

      await clientStateStore.getters.folgeterminInfo.augment(resolveContainer);

      function mangle<T extends IOtherLeistungenBase>(
        l: T[],
        tag: string,
      ): { [behandler: string]: { [nummer: string]: ILeistungenBase[] } } | undefined {
        if (!isEmpty(l)) {
          const f = chain(l)
            .filter(x => roseDayjs(x.tag).isSame(tag))
            .groupBy(x => x.behandler)
            .mapValues(x => groupBy(x, xx => xx.nummer))
            .value();
          return f;
        }
        return undefined;
      }

      for (const tdi of tagLeistungen) {
        tdi.leistungsDetails = pickBy(
          {
            honorar: mangle(details?.leistungen, tdi.tag),
            material: mangle(details?.material, tdi.tag),
            eigenlaborleistungen: mangle(details?.eigenlaborleistungen, tdi.tag),
            eigenlabormaterial: mangle(details?.eigenlabormaterial, tdi.tag),
            fremdlabor: mangle(details?.fremdlabor, tdi.tag),
          },
          x => !isEmpty(x),
        ) as any;

        if (tdi.leistungsDetails) {
          tdi.leistungsDetails.hasHkpRef = chain(details.leistungen)
            .concat(details.material)
            .concat(details.eigenlaborleistungen)
            .concat(details.eigenlabormaterial)
            .concat(details.fremdlabor)
            .filter(x => roseDayjs(x.tag).isSame(tdi.tag))
            .some(x => !x.hkp)
            .value();
          tdi.leistungsDetails.hasData = !chain(details.leistungen)
            .concat(details.material)
            .concat(details.eigenlaborleistungen)
            .concat(details.eigenlabormaterial)
            .concat(details.fremdlabor)
            .filter(x => roseDayjs(x.tag).isSame(tdi.tag))
            .isEmpty()
            .value();
        }

        if (isEmpty(tdi.leistungsDetails)) {
          delete tdi.leistungsDetails;
        }
        tdi.expanded = false;
      }

      console.log('leistungen termine', tagLeistungen);

      commit.setSelectedPatientKommentar(patientKommentar);
      commit.setSelectedTagInfos(tagLeistungen);
      commit.setTermineSelectedLoading(false);
    },
    setPatientSearchInput(context, input: string) {
      const { commit } = rootActionContext(context);

      router.push({ name: 'LeistungenUebersicht', query: { patientSearchInput: input } }).catch(() => {});

      commit.setPatientSearchInput(input);
    },
    setLeistungSearchInput(context, input: string) {
      const { commit } = rootActionContext(context);

      router.push({ name: 'LeistungenUebersicht', query: { leistungSearchInput: input } }).catch(() => {});

      commit.setLeistungSearchInput(input);
    },
    async setDateEnabled(context, bool: boolean) {
      const { dispatch, commit } = rootActionContext(context);

      if (!bool) {
        await dispatch.setDate({ startDate: '', endDate: '' });
      } else {
        await dispatch.setDate({
          startDate: roseDayjs(last30Days[0]).format('YYYY-MM-DD'),
          endDate: roseDayjs(last30Days[1]).format('YYYY-MM-DD'),
        });
      }

      commit.setDateEnabled(bool);
    },
    async setDate(context, data: { startDate: string; endDate: string }) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setDate(data);
      commit.setDateEnabled(true);
      await dispatch.getData();
    },
    async setRefDate(context, date: string) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setRefDate(date);
      await dispatch.getData();
    },
    async setSubtractHkp(context, bool: boolean) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setSubtractHkp(bool);
      await dispatch.getData();
    },
    async setTeamSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setTeamSelected(team);
      await dispatch.getData();
    },
    async setLeistungserbringerSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setLeistungserbringerSelected(team);
      await dispatch.getData();
    },
    switchExtendedSummaryRow(context) {
      const { commit } = rootActionContext(context);
      commit.switchExtendedSummaryRow();
    },
    async saveKommentar(context, kommentar: ILeistungenTermineKommentarParams) {
      const { commit } = rootActionContext(context);
      commit.updateSelectedTagLeistungKommentar(kommentar);
      await metricsApi.leistungen.saveTerminKommentar(kommentar);
    },
    async toggleFilterGroup(context, filterKey: string) {
      const { state, dispatch } = rootActionContext(context);
      state.spezialFilterHandler.toggleFilterGroup(filterKey);
      await dispatch.updateFilterViewState();
      await dispatch.updateSummaryRows('toggleFilterGroup');
    },
    async filterGroupSelectedChanged(context, opts: { filterGroup: string; filterValue?: string }) {
      const { state, dispatch } = rootActionContext(context);
      state.spezialFilterHandler.filterGroupSelectedChanged(opts);
      await dispatch.updateFilterViewState();
      await dispatch.updateSummaryRows('filterGroupSelectedChanged');
    },
    async updateFilterViewState(context) {
      const { state } = rootActionContext(context);

      const viewStateString = state.spezialFilterHandler.getViewStateString();
      if (viewStateString) {
        await viewStateStore.dispatch.addToViewState({
          filters: viewStateString,
        });
      } else {
        await viewStateStore.dispatch.removeFromViewState(['filters']);
      }
    },
    setFilterStateFromViewState(context) {
      const { state } = rootActionContext(context);
      if (viewStateStore.getters.viewState.filters) {
        state.spezialFilterHandler.setFilterStateFromViewState(viewStateStore.getters.viewState.filters);
      }
    },
    updateSummaryRows(context, where: string) {
      console.log(`updateSummaryRows: ${where}`);
      const { state, commit, getters } = rootActionContext(context);

      commit.setSummaryRow([createSummaryRow()]);
      commit.setExtendedSummaryRow(createExtendedSummaryRow());

      state.extendedSummaryRow.forEach(row => {
        if (!row.spezialFilter) {
          return;
        }
        const [group, entry] = row.spezialFilter[0].split('.');

        const f = find(getters.filterGroups[group].filters, ffff => ffff.key === entry);
        if (f) {
          row.filterCount = filter(getters.leistungenFiltered, f.rowFilter).length;
          row.count = filter(state.leistungen, f.rowFilter).length;
        }
      });
    },
    async saveWiedervorlage(context, evt: any) {
      const leistung: IOffeneGOZLeistungenExtended = evt.data;
      console.log('save wiedervorlageInfo', leistung);
      const kommentar = evt.data.wiedervorlageKommentar;
      const datum = evt.data.wiedervorlage;
      const patid = evt.data.patid;
      const ersteller = authStore.getters.userEmail;

      await metricsApi.leistungen.saveLeistungWVLInfo({
        patid,
        kommentar,
        datum,
        ersteller,
      });
    },
  },
});

export const leistungenStore = store;
