import { roseDayjs } from '../../../base';

export const ageFilter = {
  filters: {
    age(date: Date | string | undefined, tz = 'GMT'): string {
      if (date) {
        const mom = roseDayjs(date).tz(tz);
        if (mom.isValid()) {
          const y = roseDayjs().diff(mom, 'years');
          const m = roseDayjs().diff(mom, 'months');
          if (m < 12) {
            return `${m} Monate`;
          } else if (m >= 12 && m <= 72) {
            return `${y} Jahre / ${m} Monate`;
          } else {
            return `${y} Jahre`;
          }
        }
        return 'n/a';
      }
      return '-';
    },
    ageYearOnly(date: Date | string | undefined, tz = 'GMT'): string {
      if (date) {
        const mom = roseDayjs(date).tz(tz);
        if (mom.isValid()) {
          return `${roseDayjs().diff(mom, 'years')}`;
        }
        return 'n/a';
      }
      return '-';
    },
  },
};
