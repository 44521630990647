import { ITerminMitKommentar, ITerminResolveInfo } from '../../../types';
import { BaseResolverService } from './base-resolver.service';
import { fakerDe } from '../fakerDe';
import { map } from 'lodash';
import { handleTMK } from './base-resolver.misc';

export abstract class CommonTerminInfoResolverService extends BaseResolverService<
  ITerminResolveInfo,
  ITerminMitKommentar,
  ITerminResolveInfo
> {
  public name = 'termine';
  public maxRetries = 20;

  extractDataObjects(entry: ITerminResolveInfo): ITerminMitKommentar[] {
    return entry ? [entry] : [];
  }

  public extractId(entry: ITerminResolveInfo) {
    return entry.extid;
  }

  public extractInfoKey(item: ITerminResolveInfo) {
    return item.extid;
  }

  public fakeInfo(dobjs: (ITerminMitKommentar & { extid: string })[], options?: object): Promise<ITerminResolveInfo[]> {
    const fi = map(dobjs, o => {
      const pn: ITerminResolveInfo = {
        extid: this.extractId(o) || '',
        kommentar: fakerDe.lorem.sentence(10),
      };
      return pn;
    }) as unknown as ITerminResolveInfo[];
    return Promise.resolve(fi);
  }

  public blurInfo(dobjs: ITerminMitKommentar[] & { extid: string }[], options?: object): Promise<ITerminResolveInfo[]> {
    return this.fakeInfo(dobjs, options);
  }

  setResolvedInfo(e: ITerminResolveInfo, getResolvedInfo: (infoKey: string) => ITerminResolveInfo) {
    const result: { changedIds: string[]; failedIds: string[] } = { changedIds: [], failedIds: [] };
    if (e) {
      const tmkResult = this.handleTMK(e, getResolvedInfo, result); // only changeid of main entry is important
      if (tmkResult) {
        result.changedIds.push(tmkResult);
      }
    }
    return result;
  }

  public handleTMK(
    tmk: ITerminMitKommentar,
    getResolvedInfo: (infoKey: string) => ITerminResolveInfo,
    result: { failedIds: string[] },
  ) {
    return handleTMK(tmk, getResolvedInfo, result);
  }
}
