import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import { authStore } from '@/state/authStore';
import { defineComponent } from 'vue';
import { chain, isEmpty } from 'lodash';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    views: function views() {
      var uiuser = this.params.data;
      return chain(authStore.getters.viewsAvailable).filter(function (v) {
        var _a;

        return !!((_a = uiuser === null || uiuser === void 0 ? void 0 : uiuser.views) === null || _a === void 0 ? void 0 : _a.includes(v.id));
      }).map(function (v) {
        var _a;

        var viewSpecificBehandlerAccess = (_a = uiuser === null || uiuser === void 0 ? void 0 : uiuser.viewSpecificBehandlerAccess) === null || _a === void 0 ? void 0 : _a[v.id];
        var hasViewSpecificBehandlerAccess = !isEmpty(viewSpecificBehandlerAccess);
        return {
          id: v.id,
          name: v.name,
          viewSpecificBehandlerAccess: viewSpecificBehandlerAccess,
          hasViewSpecificBehandlerAccess: hasViewSpecificBehandlerAccess,
          viewSpecificBehandlerAccessCapability: v.specificBehandlerAccessControl,
          tooltip: hasViewSpecificBehandlerAccess ? "View (".concat(v.id, "), hat Behandlereinschr\xE4nkungen") : "View (".concat(v.id, ")")
        };
      }).sortBy(function (v) {
        return v.name;
      }).value();
    }
  },
  methods: {
    color: function color(view) {
      if (!view.viewSpecificBehandlerAccessCapability) {
        return 'info';
      }

      if (view.viewSpecificBehandlerAccessCapability && view.viewSpecificBehandlerAccess && !isEmpty(view.viewSpecificBehandlerAccess)) {
        return 'success';
      }

      if (view.viewSpecificBehandlerAccessCapability && (!view.viewSpecificBehandlerAccess || isEmpty(view.viewSpecificBehandlerAccess))) {
        return 'warning';
      }

      return 'info';
    }
  }
});