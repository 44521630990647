import { metricsApi } from '@/services/data';
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from '@/services/notify.service';
import {
  DEFAULTVIEWS,
  hasAllFeatures,
  hasAnyR4cFeature,
  hasFeature,
  hasMetricsFeature,
  hasR4cFactoringFeature,
  UNSELECTABLEVIEWS,
  views,
} from '@rose/base';
import {
  FEATURES,
  IProfile,
  IUIUser,
  IViewUI,
  ADMIN_LEVEL,
  IPraxisInfoMap,
  IJwtTokenInfo,
  IUserSetting,
  ILocation,
  IMfaType,
} from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { chain, keyBy, pickBy, reject } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import { setBetaFlagActive } from '@rose/common-ui/src/services/betaFlagService';
import { formatApiErrorForUser, sendCommandToUi } from '@rose/common-ui';

export interface AuthState {
  authToken?: string;
  cid?: string;
  isCharlyAnalytics?: boolean;
  profile?: IProfile;
  tokens?: IJwtTokenInfo[];
  user?: IUIUser;
  viewsAvailable?: IViewUI[];
}

const initialState: AuthState = {
  authToken: undefined,
  cid: undefined,
  isCharlyAnalytics: undefined,
  profile: undefined,
  tokens: undefined,
  user: undefined,
  viewsAvailable: undefined,
};

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => initialState,
  getters: {
    authToken(state) {
      return state.authToken;
    },
    cid(state) {
      return state.cid;
    },
    clientsAccessRights(state): IPraxisInfoMap {
      console.log('clientsAccessRights', state.user);
      return pickBy(
        state.user?.clients,
        c => (c.adminLevel || 0) >= ADMIN_LEVEL.CLIENT_ADMIN && c.cid !== state.user?.cid,
      );
    },
    features(state) {
      const user = state.user;
      if (user) {
        const cid = state.cid;
        // prefer selected  client
        if (cid && user.clients[cid]) {
          return user.clients[cid].features;
        }
        // fall back to default
        return user?.features;
      }
      return undefined;
    },
    isBetaCustomer(state, getters) {
      return hasFeature({ lizenzen: getters.features, lizenzInfo: undefined }, FEATURES.BETA);
    },
    hasDoctosync(state, getters) {
      return hasFeature({ lizenzen: getters.features, lizenzInfo: undefined }, FEATURES.DOCTOSYNC);
    },
    canAllowReadOnlyUser(state, getters) {
      return hasFeature({ lizenzen: getters.features, lizenzInfo: undefined }, FEATURES.ALLOWREADONLYUSER);
    },
    hasFactoring(state, getters) {
      return hasR4cFactoringFeature({ lizenzen: getters.features, lizenzInfo: undefined });
    },
    hasR4c(state, getters) {
      return hasAnyR4cFeature({ lizenzen: getters.features, lizenzInfo: undefined });
    },
    hasMetrics(state, getters) {
      return hasMetricsFeature({ lizenzen: getters.features, lizenzInfo: undefined });
    },
    hasPraxisReport(state, getters) {
      return hasAllFeatures({ lizenzen: getters.features, lizenzInfo: undefined }, FEATURES.PRAXISREPORT);
    },
    initialized(state) {
      return !!(state.authToken && state.cid);
    },
    isAdmin(state) {
      return state.user?.adminLevel && state.user?.adminLevel >= ADMIN_LEVEL.CLIENT_ADMIN;
    },
    location(state): ILocation | undefined {
      return state.profile?.location;
    },
    praxisKurzName(state) {
      return state.profile?.praxisKurzName;
    },
    praxisName(state) {
      return state.profile?.praxisName;
    },
    profile(state) {
      return state.profile;
    },
    tokens(state): IJwtTokenInfo[] {
      return state.tokens || [];
    },
    user(state) {
      return state.user;
    },
    userEmail(state) {
      return state.user?.email || '-';
    },
    userSetting: state => (key: string) => state.user?.settings[key],
    viewsAvailable(state): IViewUI[] {
      return state.viewsAvailable || [];
    },
    canSeeView: state => (viewId: string) => state.user?.accessibleViews.includes(viewId),
    viewSpecificBehandlerAccess: state => (viewId: string) => state.user?.viewSpecificBehandlerAccess?.[viewId],
    isRoseTeam(state) {
      return (state.user?.adminLevel || ADMIN_LEVEL.NONE) >= ADMIN_LEVEL.ROSE_TEAM;
    },
  },
  mutations: {
    setAuthToken(state, token: string) {
      state.authToken = token;
    },
    setMfaType(state, type: IMfaType | undefined) {
      if (state.user) {
        state.user.mfatype = type;
      }
    },
    setClientId(state, cid: string) {
      state.cid = cid;
    },
    setIsCharlyAnalytics(state, isCharlyAnalytics: boolean) {
      state.isCharlyAnalytics = isCharlyAnalytics;
    },
    setProfile(state, profile: IProfile) {
      state.profile = profile;
      setBetaFlagActive(hasFeature(profile, FEATURES.BETA));
    },
    setTokens(state, tokens: IJwtTokenInfo[]) {
      state.tokens = tokens;
    },
    setUser(state, user: IUIUser) {
      state.authToken = user.token;
      state.cid = user.cid;
      state.user = user;
    },
    setUserSetting(state, setting: IUserSetting) {
      if (state.user) {
        Vue.set(state.user.settings, setting.key, setting.settings);
      }
    },
    setViewsAvailable(state, viewsAvailable: IViewUI[]) {
      state.viewsAvailable = viewsAvailable;
    },
    toggleShowSummary(state) {
      if (state.user) {
        state.user.settings['terminqualitaet.showSummary'] = !state.user.settings['terminqualitaet.showSummary'];
      }
    },
  },
  actions: {
    async getProfile(context) {
      const { state, commit } = rootActionContext(context);

      if (!state.cid) {
        console.log('FATAL: no cid');
        return;
      }

      const clientProfile = await metricsApi.profile.loadClientProfile();

      if (!clientProfile.mitarbeiter) {
        clientProfile.mitarbeiter = {
          empfang: 0,
          verwaltung: 0,
          abrechnung: 0,
          praxismanagement: 0,
        };
      }

      if (!clientProfile.behandlungsZimmer) {
        clientProfile.behandlungsZimmer = [];
      }

      commit.setProfile(clientProfile);
      console.log('profile', state.profile);
    },
    async getViewsAvailable(context) {
      const { commit } = rootActionContext(context);

      const allViews = await metricsApi.profile.getAvailableViews();

      const defaultViews = DEFAULTVIEWS as string[];
      const unselectableViews = UNSELECTABLEVIEWS as string[];
      const allViewsFiltered = reject(
        allViews.views,
        vid => defaultViews.includes(vid) || unselectableViews.includes(vid),
      );

      const viewMap = keyBy(views, v => v.id);
      const viewsAvailable = chain(allViewsFiltered)
        .map(vid => viewMap[vid])
        .compact()
        .value();

      commit.setViewsAvailable(viewsAvailable);
    },
    async getTokens(context) {
      const { commit } = rootActionContext(context);

      const tokens = await metricsApi.auth.tokens();

      commit.setTokens(tokens);
    },
    async removeToken(context, token: IJwtTokenInfo, withUpdate = true) {
      const { dispatch } = rootActionContext(context);

      if (token.id) {
        const result = await metricsApi.auth.deleteToken(`${token.id}`);
        if (result) {
          if (withUpdate) {
            await dispatch.getTokens();
          }
          await dispatch.notifySuccess('Token erfolgreich zurückgezogen');
        } else {
          await dispatch.notifyError(result);
        }
      }
    },
    async setUserSetting(context, setting: IUserSetting) {
      const { state, commit, dispatch } = rootActionContext(context);
      const originalValue = state.user?.settings[setting.key];
      try {
        commit.setUserSetting(setting);
        await metricsApi.auth.updateUserSetting(setting);
      } catch (e) {
        if (originalValue === undefined) {
          delete state.user?.settings[setting.key];
        } else {
          commit.setUserSetting({ key: setting.key, settings: originalValue });
        }
        void dispatch.notifyError('Einstellung konnte nicht vorgenommen werden');
      }
    },
    async toggleShowSummary(context) {
      const { commit, dispatch, state } = rootActionContext(context);
      try {
        commit.toggleShowSummary();
        await metricsApi.auth.updateUserSetting({
          key: 'terminqualitaet.showSummary',
          settings: state.user?.settings['terminqualitaet.showSummary'],
        });
      } catch (e) {
        commit.toggleShowSummary();
        void dispatch.notifyError('Einstellung konnte nicht vorgenommen werden');
      }
    },
    notifyInfo(context, message: string) {
      notifyInfo(message);
    },
    notifySuccess(context, message: string) {
      notifySuccess(message);
    },
    notifyWarning(context, message: string) {
      notifyWarning(message);
    },
    notifyError(context, message: string) {
      notifyError(message);
    },
    notifyHttpError(context, error: any) {
      notifyError(formatApiErrorForUser(error));
    },
    updateUserDataUi(context) {
      sendCommandToUi({ command: 'updateUserData', data: context.state.user });
    },
  },
});

// Export the direct-store instead of the classic Vuex store.
export const authStore = store;
// @ts-ignore
window.authStore = authStore;
