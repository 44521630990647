import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-wrap py-2"
  }, [_vm.views.length === 0 ? _c(VChip, {
    attrs: {
      "small": "",
      "color": "warning",
      "dark": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "left": "",
      "x-small": ""
    }
  }, [_vm._v("fas fa-crown")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Vollzugriff")])], 1) : _vm._e(), _vm._l(_vm.views, function (view) {
    return _c(VChip, {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: view.tooltip,
        expression: "view.tooltip"
      }],
      key: view.id,
      staticClass: "mr-1 mb-1",
      attrs: {
        "small": "",
        "color": _vm.color(view),
        "dark": ""
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('span', {
      staticClass: "name font-weight-bold"
    }, [_vm._v(_vm._s(view.name))])])]);
  })], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };