import { behandlerStore } from '@/state/behandlerStore';
import { get, isNil, keys, values } from 'lodash';
import { IBehandlerService } from '../../../types';

export class BehandlerService implements IBehandlerService {
  getProperty(id: string | undefined, field = 'displayText', inputAsFallback = false): any {
    if (id) {
      const b = behandlerStore.state.map[id];
      if (b) {
        // special case
        if (field === 'typ') {
          return b.info ? (b.info.zahnarzt ? 'ZA' : 'PZR') : '';
        }
        return get(b, field);
      }
    }
    return inputAsFallback ? id || '-' : '-';
  }

  getBehandler(id: string) {
    return behandlerStore.state.map[id];
  }

  getAllBehandler() {
    return values(behandlerStore.state.map);
  }

  getAllBehandlerIds() {
    return keys(behandlerStore.state.map);
  }

  transform(id: string, field = 'displayText', addId = false, fallback = '?'): string {
    if (field === 'typ') {
      const b = this.getBehandler(id);
      if (b && b.info) {
        return b.info.zahnarzt ? 'ZA' : b.info.pzr ? 'PZR' : fallback;
      }
      return fallback;
    }
    let s = this.getProperty(id, field);
    if (addId) {
      s += ` (${id})`;
    }
    return isNil(s) ? fallback : s;
  }
}

export const behandlerService = new BehandlerService();
