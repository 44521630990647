var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.useEntry && _vm.useEntry.data ? _c('div', {
    staticClass: "patient"
  }, [_c('div', {
    staticClass: "patinfo",
    "class": {
      veraltet: !_vm.hideVeraltet && _vm.veraltet
    }
  }, [_c('font-awesome-icon', {
    staticClass: "icon patlink",
    attrs: {
      "icon": ["fad", "address-card"]
    },
    on: {
      "click": _vm.showPatientDetails
    }
  }), _c('span', {
    staticClass: "lastname"
  }, [_vm._v(_vm._s(_vm.nachname))]), _c('span', {
    staticClass: "firstname"
  }, [_vm._v(", " + _vm._s(_vm.vorname))]), _c('span', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.patid,
      expression: "patid",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticClass: "patid"
  }, [_vm._v("(" + _vm._s(_vm.patid) + ")")])], 1), _vm.par ? _c('PARCaseInfo', {
    attrs: {
      "par": _vm.par
    }
  }) : _vm._e(), _c('OpenInCharly', {
    attrs: {
      "patid": _vm.patid
    },
    on: {
      "open-patient": _vm.onOpenPatient
    }
  })], 1) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };